// @flow strict
import React from 'react';
import AdSense from 'react-adsense';
import { Link } from 'gatsby';
import styles from './Content.module.scss';
import type { Node, Edges } from '../../../types';

type Props = {
  body: string,
  title: string,
  edges: Edges,
  post: Node
};

const Content = ({ body, title, edges, post }: Props) => {
  const Series = (() => {
    if(post.frontmatter.series !== null && post.frontmatter.series !== '' && typeof post.frontmatter.series !== 'undefined'){
      const postSeriesTitle = post.frontmatter.series
      let postSeriesEdges = edges
      postSeriesEdges = postSeriesEdges.filter(edge => edge.node.frontmatter.series === postSeriesTitle)
      postSeriesEdges.sort((edge1, edge2) => {
                        if(edge1.node.frontmatter.seriesNumber < edge2.node.frontmatter.seriesNumber) return -1
                        if(edge1.node.frontmatter.seriesNumber > edge2.node.frontmatter.seriesNumber) return 1
                        return 0
                      })

      return(
        <div className={styles['content__series']} >
          <div className={styles['content__series-title']} >連載一覧 : {postSeriesTitle}</div>
          <ul>
          {postSeriesEdges.map((edge) => (
            <Link 
              className={post.frontmatter.title !== edge.node.frontmatter.title ? styles['content__series-link'] : styles['content__series-link-select'] }
              to={edge.node.fields.slug} 
              key={edge.node.frontmatter.seriesNumber} 
            >
              <div className={styles['content__series-wrap']}>
                <li className={styles['content__series-post-title']} >第{edge.node.frontmatter.seriesNumber}回 {edge.node.frontmatter.title}</li>
              </div>
            </Link>
          ))}
          </ul>
        </div>
      )
    }
  })()

  const Toc = (() => {
    return(
      <div　className={styles['content__toc']}>
        <div>目次</div>
        <div 
          dangerouslySetInnerHTML=
            {{ 
              __html: post.tableOfContents
            }} 
        />
      </div>
    )
  })()

  return(
    <div className={styles['content']}>
      <h1 className={styles['content__title']}>{title}</h1>
      {
      <div className={styles['content__adsense']}>
        <AdSense.Google
          style={{ display: 'block' }}
          format='auto'
          client="ca-pub-2990329700521472"
          slot="9582451484"
          responsive='true'
        />
      </div>
      }
      {Series}
      {Toc}
      <div className={styles['content__body']} dangerouslySetInnerHTML={{ __html: body }} />
      {
      <div className={styles['content__adsense']}>
        <AdSense.Google
          style={{ display: 'block' }}
          format='auto'
          client="ca-pub-2990329700521472"
          slot="2789333496"
          responsive='true'
        />
      </div>
      }
      {Series}
    </div>
  );
};

export default Content;
