// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Author from './Author';
import Comments from './Comments';
import Content from './Content';
import Meta from './Meta';
import Tags from './Tags';
import Feed from '../Feed';
import styles from './Post.module.scss';
import type { Node, Edges } from '../../types';

type Props = {
  post: Node,
  edges: Edges
};

const Post = ({ post, edges }: Props) => {
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  const { tags, title, date } = post.frontmatter;
  const { thumbnail } = post.fields.thumbnail;

  const PickOutEdgeToHead = (_edges: Edges, _title: string) => {
    const index = _edges.findIndex(edge => edge.node.frontmatter.title === _title)
    const targetEdge = _edges.splice(index, 1)[0]
    _edges.unshift(targetEdge)
  }

  const SortRecommendedEdgesToHead = (_edges: Edges) => {
    const recommendedTitles = [
      '食器洗い乾燥機は分岐水栓無しで付けられる！食洗器導入レポート',
      '超簡単に大規模なアクセスにも耐えれるWordpressブログを作る',
      'Ubuntu 18.04 でCUDA, Cudnn, Tensorflow GPU のインストール',
      'OpenGLを使うとき知っておくと便利な基礎知識',
      'Visual StudioでOpenGLを使うための準備',
      'GLSLシェーダーを触ってみよう',
      '個人的によく使うgitコマンドまとめ'
    ]
    recommendedTitles.reverse().forEach((recommendedTitle) => {
      PickOutEdgeToHead(_edges, recommendedTitle)
    })
  }

  const RecommendedFeed = (() => {
    let RecommendedEdges = edges
    SortRecommendedEdgesToHead(RecommendedEdges)
    RecommendedEdges.length = RecommendedEdges.length >= 40 ? 40 : RecommendedEdges.length

    return(
      <div>
        <p className={styles['post__subtitle']}>おすすめ記事</p>
        <Feed edges={RecommendedEdges} />
        <a href="/page/1">
          <div className={styles['post__link_more_feeds']}>
            もっと見る
          </div>
        </a>
      </div>
    )
  })()

  return (
    <div className={styles['post']}>

      <div className={styles['post__content']}>
        <Img fluid={post.fields.thumbnail.childImageSharp.fluid} />
        <Content body={html} title={title} edges={edges} post={post} />
      </div>

      <div className={styles['post__footer']}>
        <Meta date={date} />
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
      </div>

      <div className={styles['post__comments']}>
        <Comments postSlug={slug} postTitle={post.frontmatter.title} />
      </div>

      {/* {RecommendedFeed} */}
    </div>
  );
};

export default Post;
